import React from 'react';
import { ScreenSize } from 'glints-aries';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import { DEFAULT_GLINTS_LOGO } from 'src/common/constants';
import Logo from 'src/components/Logo';

const ExpertClassLogo = styled.img`
  width: 110px;
`;

const StyledA = styled.a`
  margin-right: 15px;

  @media (min-width: ${ScreenSize.desktopM}px) {
    margin-left: -45px;
  }
  @media (min-width: ${ScreenSize.desktopL}px) {
    margin-left: 55px;
  }
`;

const ExpertClass = 'expert-class';

type Props = {
  disabled?: boolean;
};

export const NavbarLogo = ({ disabled = false }: Props) => {
  const router = useRouter();
  const { pathname } = router;
  const isExpertClassPage = pathname.split('/')[1] === ExpertClass;

  const GlintsLogo = () => (
    <Logo
      name={DEFAULT_GLINTS_LOGO}
      sizes="53px"
      breakpoints={53}
      lazy={false}
      alt="Glints Logo"
      assetType="glints-logo"
      fallbackAssetType="glints-logo"
      fallback={DEFAULT_GLINTS_LOGO}
    />
  );

  if (disabled) {
    return <GlintsLogo />;
  }

  return isExpertClassPage ? (
    <Link href="/expert-class" prefetch={false} passHref={true}>
      <StyledA aria-label="Glints expert class page">
        <ExpertClassLogo src="/images/expert-class-logo.png" />
      </StyledA>
    </Link>
  ) : (
    <Link href="/" prefetch={false}>
      <a aria-label="Glints home page">
        <GlintsLogo />
      </a>
    </Link>
  );
};
