import styled from 'styled-components';

import GlintsPicture from 'src/components/GlintsPicture';

const Logo = styled(GlintsPicture)`
  width: 53px;
  height: 40px;
  max-height: 40px;
  max-width: 90px;
  display: block;
`;

export default Logo;
